/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.top-nav').attr('data-height', $('.top-nav').height() + 30);

        // This activates the animated Burger Menu
        function activateHamburger() {
          var $hamburger = $('.hamburger');
          $hamburger.on('click', function(e) {
            $hamburger.toggleClass('is-active');

            // Do something else, like open/close menu
            $('body').toggleClass('burger-open');
          });
        }
        activateHamburger();

        function makeTopNavSticky() {
          $(window).scroll(function() {

            if ( window.scrollY >= 250 ) {
              var topNavHeight = $('.top-nav').height();
              $('.top-nav').addClass('scrolled').attr('data-height', $('.top-nav').height());
            } else {
              $('.top-nav').removeClass('scrolled').attr('data-height', $('.top-nav').height() + 30);
            }
          });
        }
        //makeTopNavSticky();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        function changeTopbarText() {
          if ($(window).width() <= 1024 ) {
            $('.top-bar p').html('Öffentlich bestellter und vereidigter <strong>Sachverständiger</strong>').css('padding', '5px');
          } else {
            $('.top-bar p').html('Öffentlich bestellter und vereidigter <strong>Sachverständiger</strong> für die Bewertung von bebauten und unbebauten Grundstücken');
          }
        }
        changeTopbarText();         

        $(window).on('resize', function() {
          changeTopbarText();
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // About us page, note the change from about-us to about_us.
    'kontakt': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        function initialize_gmaps() {

          var styleArray = [
            {
              featureType: 'all',
              stylers: [
                { saturation: -80 }
              ]
            },{
              featureType: 'road.arterial',
              elementType: 'geometry',
              stylers: [
                { hue: '#00ffee' },
                { saturation: 50 }
              ]
            },{
              featureType: 'poi.business',
              elementType: 'labels',
              stylers: [
                { visibility: 'off' }
              ]
            }
          ];
          var boehme = {lat: 54.417627, lng: 10.174983};

          var icon = 'http://maps.google.com/mapfiles/kml/paddle/wht-circle.png';

          var mapOptions = {
            zoom: 14,
            center: boehme,
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: false,
            styles: styleArray
          };

          var map = new google.maps.Map(document.getElementById('map'),
          mapOptions);

          var marker = new google.maps.Marker({
            position: boehme,
            map: map,
            icon: icon
          });

          var contentString_boehme = '<div id="content">'+
          '<div id="siteNotice">'+
          '</div>'+
          '<b>Sachverständigenbüro Jürgen Boehme</b>'+
          '<div id="bodyContent">'+
          '<p class="boehme_text">Funkstellenweg 16<br>24159 Kiel<br><br>Telefon:+49 (0) 431 37 50 32 0<br>Telefax:+49 (0) 431 37 50 32 6<br>E-Mail:info@immobilien-gutachter.org</p>'+
          '</div>'+
          '</div>';

          var infowindow_boehme = new google.maps.InfoWindow({
            content: contentString_boehme
          });

          marker.addListener('click', function() {
            infowindow_boehme.open(map, marker);
          });

        }

        google.maps.event.addDomListener(window, 'load', initialize_gmaps);
        //map.refresh();


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
